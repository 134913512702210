import NepaliDate from "nepali-date-converter";

let bsDate: { year: number; month: number; day: number } = {} as {
  year: number;
  month: number;
  day: number;
};

const monthNameDev = [
  "",
  "वैशाख",
  "जेष्ठ",
  "आषाढ़",
  "श्रावण",
  "भाद्र",
  "आश्विन",
  "कार्तिक",
  "मंसिर",
  "पौष",
  "माघ",
  "फाल्गुन",
  "चैत्र"
];

const monthName = [
  "",
  "Baisakh",
  "Jestha",
  "Ashadh",
  "Shrawan",
  "Bhadra",
  "Ashwin",
  "Kartik",
  "Mangsir",
  "Poush",
  "Magh",
  "Falgun",
  "Chaitra"
];

const BS_YEAR_TO_MONTHS: any = {
  2000: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2001: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2002: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2003: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2004: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2005: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2006: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2007: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2008: [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
  2009: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2010: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2011: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2012: [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
  2013: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2014: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2015: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2016: [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
  2017: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2018: [0, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2019: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2020: [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
  2021: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2022: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
  2023: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2024: [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
  2025: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2026: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2027: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2028: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2029: [0, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
  2030: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2031: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2032: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2033: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2034: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2035: [0, 30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
  2036: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2037: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2038: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2039: [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
  2040: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2041: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2042: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2043: [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
  2044: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2045: [0, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2046: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2047: [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
  2048: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2049: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
  2050: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2051: [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
  2052: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2053: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
  2054: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2055: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2056: [0, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
  2057: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2058: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2059: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2060: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2061: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2062: [0, 30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
  2063: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2064: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2065: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2066: [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
  2067: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2068: [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2069: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2070: [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
  2071: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2072: [0, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2073: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
  2074: [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
  2075: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2076: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
  2077: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
  2078: [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
  2079: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
  2080: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
  2081: [0, 31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
  2082: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
  2083: [0, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
  2084: [0, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
  2085: [0, 31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30],
  2086: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
  2087: [0, 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
  2088: [0, 30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30],
  2089: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
  2090: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
  2091: [0, 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
  2092: [0, 30, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
  2093: [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
  2094: [0, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
  2095: [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 30, 30, 30],
  2096: [0, 30, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
  2097: [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
  2098: [0, 31, 31, 32, 31, 31, 31, 29, 30, 29, 30, 29, 31],
  2099: [0, 31, 31, 32, 31, 31, 31, 30, 29, 29, 30, 30, 30],
  2100: [0, 31, 32, 31, 32, 30, 31, 30, 29, 30, 29, 30, 30]
};

export function nepaliUnicodeNumber(text: string) {
  let chars: any = {
    "0": "०",
    "1": "१",
    "2": "२",
    "3": "३",
    "4": "४",
    "5": "५",
    "6": "६",
    "7": "७",
    "8": "८",
    "9": "९"
  };
  if (text == "0" || (text && text != null)) {
    return text.toString().replace(/[0123456789]/g, m => chars[m]);
  }
  return text;
}

export function englishUnicodeNumber(text: string) {
  var chars: any = {
    "०": "0",
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9"
  };
  return text.toString().replace(/[०१२३४५६७८९]/g, m => chars[m]);
}
export function convertADtoBS(dateInAD: Date) {
  const nepDate = new NepaliDate(dateInAD.getTime());
  const month = nepDate.getMonth() + 1;
  let nepaliDate = {
    year: nepDate.getYear(),
    month: month,
    day: nepDate.getDate(),
    monthString: monthName[month],
    monthStringDev: monthNameDev[month]
  };
  return nepaliDate;
}

export function nepaliDate(date: string, lang: string = "en") {
  let dateObj = new Date(date);
  try {
    let nepaliDate = convertADtoBS(dateObj);
    let dateString = `${nepaliDate.day.toString().padStart(2, "0")} ${nepaliDate.monthString}, ${
      nepaliDate.year
    }`;
    if (lang == "ne") {
      dateString = `${nepaliUnicodeNumber(String(nepaliDate.day.toString().padStart(2, "0")))} ${
        nepaliDate.monthStringDev
      }, ${nepaliUnicodeNumber(String(nepaliDate.year))}`;
    }
    return dateString;
  } catch (error) {
    let nepaliDate = {
      year: dateObj.getFullYear(),
      month: dateObj
        .getMonth()
        .toString()
        .padStart(2, "0"),
      day: dateObj
        .getDay()
        .toString()
        .padStart(2, "0"),
      monthString: dateObj.toLocaleString("default", { month: "long" })
    };

    return (
      nepaliDate.day.toString().padStart(2, "0") +
      " " +
      nepaliDate.monthString +
      ", " +
      nepaliDate.year
    );
  }
}

export function nepaliDateTime(date: string, lang: string = "en") {
  let dateObj = new Date(date);
  let times = dateObj.toLocaleTimeString("ne");
  if (lang == "ne") {
    times = nepaliUnicodeNumber(times);
    // .replace('PM', 'pm').replace('AM', 'am')
  }
  let dateString = nepaliDate(date, lang) + ", " + times;
  return dateString;
}
